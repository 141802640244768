import React from "react";
import { withRouter, useLocation, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { useAuthState } from "../../context/auth";

const PrivateRoute = ({ component: Component, ...props }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthState();

  return (
    <Route {...props}>
      {isAuthenticated ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

export default withRouter(PrivateRoute);

const Currency = ({ amount, ...rest }) => {
  if(isNaN(amount)) return (
    <span {...rest}>{amount}</span>
  )
  return (
    <span {...rest}>
      {new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount)}
    </span>
  );
};

export default Currency;

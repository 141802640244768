import React, { useState, createContext, useContext, useEffect } from "react";
import { Prompt } from "react-router";

const SavingContext = createContext();

export const useSavingState = () => useContext(SavingContext);

const SavingState = (props) => {
  const [savingState, setSavingState] = useState("Saved");

  const saving = () => setSavingState("Saving...");

  const saved = () => setSavingState("Saved");

  const error = (e) => {
    let err = "";
    if (typeof e === "string") {
      err = e;
    }
    if (typeof e === "object") {
      if (e.response) {
        if (e.response.statusCode > 500) {
          err = "Server Error";
        } else if (e.response.data && e.response.data.error) err = e.response.data.error.message;
      }
    }

    err = err || "Opps!...";

    if (!err.startsWith("Error: ")) {
      err = `Error: ${err}`;
    }

    setSavingState(err);
  };

  useEffect(() => {
    if (savingState !== "Saved") {
      window.onbeforeunload = function () {
        return false;
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [savingState]);

  return (
    <SavingContext.Provider
      value={{
        savingState,
        saving,
        saved,
        error,
      }}
    >
      {props.children}
      {/* <Prompt when={savingState !== "Saved"} message={"Changes you made may not be saved. Leave anyway?"} /> */}
    </SavingContext.Provider>
  );
};

export default SavingState;

import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useAlert } from "../../context/alert";
import { Button, Divider, SwipeableDrawer, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { stores } from "../forms/ShowForm";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import { createPortal } from "react-dom";
import api from "../../utils/api";

export const useQuery = (search) => new URLSearchParams(search);
export default function Filters({ onChangeParams, portal }) {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [stores, setStores] = useState([]);
  //this whole thing is not good and should be redone with a state and only written to the url on press "apply"
  const query = useQuery(location.search);
  const seasons = ["S21", "W22", "S22", "W23", "S23", "W24", "S24", "W25"];
  //prettier-ignore
  const filters = useMemo(()=>({
    seasons: query.get("seasons")?.split(" ") || [...seasons],
    stores: query.get("stores")?.split(",") || [...stores],
    done: query.get("done") ? query.get("done") === "1" : true,
    notDone: query.get("notDone") ? query.get("notDone") === "1" : true,
    archived: query.get("archived") ? query.get("archived") === "1" : false,
    unArchived: query.get("unArchived") ? query.get("unArchived") === "1" : true,
    completed: query.get("completed") ? query.get("completed") === "1" : false,
    unCompleted: query.get("unCompleted") ? query.get("unCompleted") === "1" : true,
    ready: query.get("ready") ? query.get("ready") === "1" : true,
    notReady: query.get("notReady") ? query.get("notReady") === "1" : true,
  }),[query]);
  const params = {
    defaultProductSeason: filters.seasons.length !== seasons.length ? { $in: filters.seasons } : undefined,
    store: filters.stores.length !== stores.length ? { $in: filters.stores } : undefined,
    archived: filters.archived && filters.unArchived ? undefined : filters.archived,
    completed: filters.completed && filters.unCompleted ? undefined : filters.completed,
    doneEditing: filters.done && filters.notDone ? undefined : filters.done,
    readyForReview: filters.ready && filters.notReady ? undefined : filters.ready,
  };
  const readableParams = {
    seasons: "Seasons",
    stores: "Stores",
    done: "Marked Done",
    notDone: "Not marked done",
    archived: "Archived",
    unArchived: "Not archived",
    completed: "Completed",
    unCompleted: "Not Completed",
    ready: "Ready for review",
    notReady: "Not ready for review",
  };
  const paramsWeUse = ["seasons", "stores", "done", "notDone", "completed", "unCompleted", "archived", "unArchived", "ready", "notReady"];
  const weGotSomeFilters = useMemo(() => paramsWeUse.filter((e) => Object.keys(Object.fromEntries(query)).includes(e)).length, [query]);

  useEffect(() => {
    api.get("/autocompleteOptions/stores").then((e) => setStores(e.data.map((e) => e.name)));
  }, []);

  useEffect(() => {
    if (weGotSomeFilters) {
      setLoading(true);
      onChangeParams(params).then(() => setLoading(false));
    } else {
      // setLoading(true);
      // onChangeParams({}).then(()=>setLoading(false));
    }
    function hello() {
      setLoading(true);
      onChangeParams(params).then(() => setLoading(false));
    }
    document.addEventListener("update-shows-please", hello);
    return () => document.removeEventListener("update-shows-please", hello);
  }, [location.search]);

  return (
    <>
      <Button onClick={() => setFiltersOpen(true)}>
        <FilterListIcon /> Filters
      </Button>
      <SwipeableDrawer anchor={"right"} open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div style={{ padding: 24, ...(loading ? { opacity: 0.5, userSelect: "none" } : undefined) }}>
          <Typography variant="h6">Season</Typography>
          <div>
            {seasons.map((e) => {
              return (
                <FormControlLabel
                  key={e}
                  control={
                    <Checkbox
                      checked={filters.seasons.includes(e)}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          filters.seasons.push(e);
                          setLoading(true);
                          setTimeout(() => {
                            query.set("seasons", filters.seasons.join(" "));
                            history.push(`${location.pathname}?${query.toString()}`);
                          });
                        } else {
                          if (filters.seasons.length === 1) {
                            showAlert({ message: "At least on season has to be selected" });
                          } else {
                            filters.seasons.splice(filters.seasons.indexOf(e), 1);
                            setLoading(true);
                            setTimeout(() => {
                              query.set("seasons", filters.seasons.join(" "));
                              history.push(`${location.pathname}?${query.toString()}`);
                            });
                          }
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>{e}</span>}
                />
              );
            })}
          </div>
          <br></br>
          <br></br>
          <Divider />
          <Typography variant="h6">Stores</Typography>
          <div>
            {stores.map((e) => {
              return (
                <FormControlLabel
                  key={e}
                  control={
                    <Checkbox
                      checked={filters.stores.includes(e)}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          filters.stores.push(e);
                          setLoading(true);
                          setTimeout(() => {
                            query.set("stores", filters.stores.join(","));
                            history.push(`${location.pathname}?${query.toString()}`);
                          });
                        } else {
                          if (filters.stores.length === 1) {
                            showAlert({ message: "At least one store has to be selected" });
                          } else {
                            filters.stores.splice(filters.stores.indexOf(e), 1);
                            setLoading(true);
                            setTimeout(() => {
                              query.set("stores", filters.stores.join(","));
                              history.push(`${location.pathname}?${query.toString()}`);
                            });
                          }
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>{e}</span>}
                />
              );
            })}
          </div>
          <br></br>
          <br></br>
          <Divider />
          <Typography variant="h6">Done</Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.done}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.notDone) {
                        showAlert({ message: "Either Done or Not Done have to be checked" });
                        return;
                      }
                    }
                    filters.done = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("done", filters.done ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Done</span>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.notDone}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.done) {
                        showAlert({ message: "Either Done or Not Done have to be checked" });
                        return;
                      }
                    }
                    filters.notDone = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("notDone", filters.notDone ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Not Done</span>}
            />
          </div>

          <br></br>
          <br></br>
          <Divider />
          <Typography variant="h6">Archived</Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.archived}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.unArchived) {
                        showAlert({ message: "Either Archived or Unarchived have to be checked" });
                        return;
                      }
                    }
                    filters.archived = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("archived", filters.archived ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Archived</span>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.unArchived}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.archived) {
                        showAlert({ message: "Either Archived or Unarchived have to be checked" });
                        return;
                      }
                    }
                    filters.unArchived = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("unArchived", filters.unArchived ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Unarchived</span>}
            />
          </div>
          <br></br>
          <br></br>
          <Divider />
          <Typography variant="h6">Completed</Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.completed}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.unCompleted) {
                        showAlert({ message: "Either Completed or Uncompleted have to be checked" });
                        return;
                      }
                    }
                    filters.completed = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("completed", filters.completed ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Completed</span>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.unCompleted}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.completed) {
                        showAlert({ message: "Either Completed or Uncompleted have to be checked" });
                        return;
                      }
                    }
                    filters.unCompleted = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("unCompleted", filters.unCompleted ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Uncompleted</span>}
            />
          </div>
          <br></br>
          <br></br>
          <Divider />
          <Typography variant="h6">Ready for review</Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.ready}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.notReady) {
                        showAlert({ message: "Either Ready or Not Ready have to be checked" });
                        return;
                      }
                    }
                    filters.ready = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("ready", filters.ready ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Ready</span>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.notReady}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      if (!filters.ready) {
                        showAlert({ message: "Either Ready or Not Ready have to be checked" });
                        return;
                      }
                    }
                    filters.notReady = e.target.checked;
                    setLoading(true);
                    setTimeout(() => {
                      query.set("notReady", filters.notReady ? "1" : "0");
                      history.push(`${location.pathname}?${query.toString()}`);
                    });
                  }}
                  color="primary"
                />
              }
              label={<span style={{ fontSize: 12 }}>Not Ready</span>}
            />
          </div>
          <br />
          <br />
          <br />
          <Button
            onClick={() => {
              setFiltersOpen(false);
            }}
          >
            Apply
          </Button>
        </div>
      </SwipeableDrawer>
      {portal && weGotSomeFilters
        ? createPortal(
            Object.entries(Object.fromEntries(query))
              .filter(([k, v]) => v !== "0" && paramsWeUse.includes(k))
              .map(([k, v]) => {
                let label = "";
                v = filters[k];
                if (Array.isArray(v)) {
                  label = `${readableParams[k]}: `;
                  v.forEach((e) => (label += `${e.slice(0, 3)} `));
                } else {
                  label = readableParams[k];
                }
                return (
                  <Chip
                    key={k}
                    label={label}
                    onDelete={() => {
                      query.delete(k);
                      onChangeParams(params).then(() => setLoading(false));
                      history.push(`${location.pathname}?${query.toString()}`);
                    }}
                  />
                );
              }),
            portal
          )
        : ""}
    </>
  );
}

import React, { useState } from "react";
import { Gallery as SwipeGallery, Item } from "react-photoswipe-gallery";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export const imageStyles = { objectFit: "cover", maxHeight: 100, maxWidth: 80, width: 100, height: 100, display: "inline-block", marginRight: 4 };

const Gallery = ({ images, icon, onClickIcon }) => {
  return (
    <SwipeGallery>
      {images
        .filter((img) => img)
        .map((img, i) => (
          <EachImage img={img} key={i} i={i} icon={icon} onClickIcon={onClickIcon} />
        ))}
    </SwipeGallery>
  );
};

function EachImage({ img, i, icon, onClickIcon = () => null }) {
  const [dimensions, setDimensions] = useState({});

  return (
    <Item original={process.env.REACT_APP_FS_BASE+img} thumbnail={process.env.REACT_APP_FS_BASE+img} key={i} height={dimensions.height} width={dimensions.width}>
      {({ ref, open }) =>
        icon ? (
          <div style={{ display: "inline-block", position: "relative", height: 100, width: 80 }}>
            <img
              src={process.env.REACT_APP_FS_BASE+img}
              alt=""
              ref={ref}
              onClick={open}
              style={imageStyles}
              onLoad={function ({ target }) {
                setDimensions({ height: target.naturalHeight, width: target.naturalWidth });
              }}
            />
            <div style={{ position: "absolute", right: 5, top: 5, cursor: "pointer", background: "white" }} onClick={() => onClickIcon(i)}>
              {icon}
            </div>
          </div>
        ) : (
          <img
            src={process.env.REACT_APP_FS_BASE+img}
            alt=""
            ref={ref}
            loading="lazy"
            onClick={open}
            style={imageStyles}
            onLoad={function ({ target }) {
              setDimensions({ height: target.naturalHeight, width: target.naturalWidth });
            }}
          />
        )
      }
    </Item>
  );
}

export const Videos = ({ videos, deleteVideo }) => {
  return (
    <div>
      {videos.map((vid, i) =>
        deleteVideo ? (
          <div style={{ position: "relative" }}>
            <video controls>
              <source src={vid} type="video/mp4" />
            </video>
            <div style={{ position: "absolute", right: 5, top: 5, cursor: "pointer", background: "white" }} onClick={(i) => deleteVideo(i)}>
              <DeleteForeverIcon />
            </div>
          </div>
        ) : (
          <video controls>
            <source src={vid} type="video/mp4" />
          </video>
        )
      )}
    </div>
  );
};

export const PlainGallery = ({ images, icon, onClickIcon = () => null }) => {
  return (
    <>
      {images.map((img, i) =>
        icon ? (
          <div style={{ display: "inline-block", position: "relative", height: 100, width: 80 }} key={i}>
            <img src={process.env.REACT_APP_FS_BASE+img + "/100"} alt="" style={imageStyles} />
            <div style={{ position: "absolute", right: 5, top: 5, cursor: "pointer", background: "white" }} onClick={() => onClickIcon(i)}>
              {icon}
            </div>
          </div>
        ) : (
          <img key={i} src={process.env.REACT_APP_FS_BASE+img + "/100"} alt="" style={imageStyles} />
        )
      )}
    </>
  );
};

export default Gallery;
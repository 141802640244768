import React, { useState, createContext, useContext, useEffect } from "react";
import { Button, Snackbar, IconButton } from "@material-ui/core";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

const AlertState = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState({
    action: null,
    actionText: "",
    actionIcon: "",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOptions({});
    setOpen(false);
  };

  function showAlert(options) {
    handleClose();
    setOptions(options);
    setOpen(true);
  }

  return (
    <AlertContext.Provider
      value={{
        showAlert,
      }}
    >
      {props.children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={options.message}
        action={
          <React.Fragment>
            {options.action && options.actionText && (
              <>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    options.action();
                    handleClose();
                  }}
                >
                  {options.actionText}
                </Button>
                {options.actionIcon && (
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      options.action();
                      handleClose();
                    }}
                  >
                    <options.actionIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            )}
          </React.Fragment>
        }
      />{" "}
    </AlertContext.Provider>
  );
};

export default AlertState;

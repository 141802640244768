import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import ShowForm from "../forms/ShowForm";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../utils/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import { showDefaults } from "../../utils/defaults";
import { Autosave } from "react-autosave";
import { useSavingState } from "../../context/saving";
import { makeStyles, Drawer } from "@material-ui/core";
import { useShow } from "../../context/show";
import { useAlert } from "../../context/alert";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useDrawerStyles } from "./Product";
import Gallery from "../layout/Gallery";
import { Videos } from "../layout/Gallery";
import { isVid } from "../forms/VariantForm";
import Fs1Input from "../layout/FsInput";
import Search from "../layout/Search";

let didThatEffect = false;

export function cleanObjFromMongoData(obj) {
  const newObj = JSON.parse(JSON.stringify(obj));
  delete newObj._id;
  delete newObj.id;
  delete newObj.__v;
  delete newObj.createdAt;
  delete newObj.updatedAt;
  return newObj;
}

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));
const useListItemClasses = makeStyles((theme) => ({
  root: {
    fontSize: 12,
  },
}));

const Show = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { show, setShow, getShow, matchingItems, setMatchingItems } = useShow();
  const [creatingNewProduct, setCreatingNewProduct] = useState(false);
  const { saving, saved, error } = useSavingState();
  const { showAlert } = useAlert();
  const [deletingProduct, setDeletingProduct] = useState(null);
  const [copyingProduct, setCopyingProduct] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);
  const [binDrawerOpen, setBinDrawerOpen] = useState(false);
  const classes = useStyles();
  const listItemClasses = useListItemClasses();
  const drawerStyles = useDrawerStyles();
  const [allShowImages, setAllShowImages] = useState({ images: [], videos: [] });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getShowProperty = (name) => {
    return show[name] ?? showDefaults[name];
  };

  const change = (name, value) => {
    saving();
    setShow({
      ...show,
      [name]: value,
    });
  };

  const createNewProduct = async () => {
    setCreatingNewProduct(true);
    let lastAddedShowData = await api.get(`/shows/${match.params.show_id}/products?limit=1&sort=-updatedAt`);
    lastAddedShowData = lastAddedShowData.data[0];
    const data = {
      show: match.params.show_id,
      brand: lastAddedShowData?.brand || undefined,
      vendor: lastAddedShowData?.vendor || undefined,
      season: show.defaultProductSeason,
    };
    const res = await api.post(`/products`, data);
    history.push(`/products/${res.data._id}`);
  };

  const copyProduct = async (productId) => {
    setCopyingProduct(productId);
    let { data: product } = await api.get(`/products/${productId}?populate=variants`);
    product.show = product.show._id;
    product = cleanObjFromMongoData(product);
    product.variants = product.variants.map((variant) => {
      variant = cleanObjFromMongoData(variant);
      variant.sizes = variant.sizes.map(cleanObjFromMongoData);
      return variant;
    });
    let variants = product.variants;
    product.variants = undefined;
    const { data: newProduct } = await api.post("/products", product);
    variants = variants.map((variant) => ({
      ...variant,
      product: newProduct._id,
    }));
    if (variants.length) {
      await api.post("/variants", variants);
    }
    history.push(`/products/${newProduct._id}`);
  };

  const updateShow = (data) => {
    return api
      .put(`/shows/${match.params.show_id}`, {
        date: data.date,
        location: data.location,
        name: data.name,
        notes: data.notes,
        store: data.store,
        archived: data.archived,
        completed: data.completed,
        defaultProductSeason: data.defaultProductSeason,
        PO: data.PO,
        markup: data.markup,
        unassignedImagesBin: data.unassignedImagesBin,
        unassignedImagesBinOriginals: data.unassignedImagesBinOriginals,
        POCancelDate: data.POCancelDate,
        POStartShipDate: data.POStartShipDate,
      })
      .then(({ data }) => {
          const { warning = false, message = null } = data;
          if(warning) {
            showAlert({ message });
          }
        })
      .then(saved)
      .catch(error);
  };

  const deleteProduct = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      setDeletingProduct(id);
      api
        .delete(`/products/${id}`)
        .then(() => getShow())
        .finally(() => setDeletingProduct(false));
    }
  };

  const handleUnassignedImages = (e) => {
    const temp = [...getShowProperty("unassignedImagesBin"), ...e];
    updateShow({ unassignedImagesBin: temp }).then(() => getShow());
  };

  const saveOriginals = (e) => {
    const temp = [...getShowProperty("unassignedImagesBinOriginals"), ...e];
    updateShow({ unassignedImagesBinOriginals: temp }).then(() => getShow());
  };

  const deleteUnassignedImages = (i) => {
    const temp = [...getShowProperty("unassignedImagesBin")];
    temp.splice(i, 1);
    updateShow({ unassignedImagesBin: temp });
  };

  const selectedProductId = localStorage.getItem("productId") || 0
  useEffect(() => {
    if (selectedProductId) {
      const selectedProductElement = document.querySelector(`.product-${selectedProductId}`);
      show && selectedProductElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      api
        .get(`/shows/${match.params.show_id}`, {
          params: {
            populate: {
              path: "products",
              select: "images variants",
              populate: {
                path: "variants",
                select: "images",
              },
            },
          },
        })
        .then((res) => {
          const newArr = [];
          res.data.products.forEach((prod) => {
            newArr.push(...prod.images);
            prod.variants.forEach((variant) => {
              newArr.push(...variant.images);
            });
          });
          setAllShowImages({ images: newArr.filter((e) => !isVid(e)), videos: newArr.filter(isVid) });
        });
    }
  }, [show]);

  useEffect(() => {
    if (show && !didThatEffect) {
      if (show.name === "New Show Name") setExpanded("panel1");
      if (show.completed) {
        showAlert({ message: "You are viewing an completed show" });
      } else if (show.archived) {
        showAlert({ message: "You are viewing an archived show" });
      }
      didThatEffect = true;
    }
  }, [show]);

  if (!show)
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );

  const items = getShowProperty("products");
  return (
    <div>
      <Accordion defaultExpanded={Boolean(!items.length)} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">
            {!show.name || show.name === "New Show Name" ? "Details" : `${show.name || ""}${show.name && show.date ? " - " : ""}${show.showDate || ""} ${show.location || ""}`}{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShowForm {...{ show, getShowProperty, change, expanded }} />
        </AccordionDetails>
      </Accordion>
      <br />
      <Button size="small" onClick={() => history.goBack()}>
        &lt; Back
      </Button>
      <div style={{ position: "relative" }}>
        <Typography variant="h6">Items</Typography>
        <div>
          <Search
            listOfObjects={items}
            keysToSearchIn={["description", "productId"]}
            onListFiltered={(newList) => {
              setMatchingItems(newList);
            }}
          />
        </div>
        <List style={{ width: "100%" }}>
          {matchingItems.map((product, i, a) => (
            <EachItemList {...{ product, i, a, deleteProduct, classes, listItemClasses, deletingProduct, copyingProduct, copyProduct, key: product._id }} />
          ))}
        </List>
        <br />
        <br />
        <div style={{ position: "sticky", bottom: 5, marginLeft: "calc(50% - 60px)" }}>
          <Button variant="contained" size="small" color="primary" aria-label="add" disabled={creatingNewProduct} onClick={createNewProduct}>
            + Add Items
          </Button>
        </div>
        <div className="">
          {/* <Button variant="contained" color="primary" disabled={creatingNewProduct} onClick={createNewProduct}>
        + Add item
      </Button> */}
          <br />
          <br />
          <br />

          <Button onClick={() => setBinDrawerOpen(true)}>Bin</Button>
          <br />
          <br />
          <br />
          <Button onClick={() => setNoteDrawerOpen(true)}>Images</Button>
          <Typography variant="subtitle1" gutterBottom onClick={() => setNoteDrawerOpen(true)}>
            {allShowImages.images.length ? `${allShowImages.images.length} image` : ""}
            {allShowImages.images.length > 1 ? "s" : ""}
            <br />
            {allShowImages.videos.length ? `${allShowImages.videos.length} video` : ""}
            {allShowImages.videos.length > 1 ? "s" : ""}
          </Typography>
          <Drawer anchor={"right"} open={noteDrawerOpen} onClose={() => setNoteDrawerOpen(false)}>
            <div className={drawerStyles.cont}>
              <Button onClick={() => setNoteDrawerOpen()}>&lt; Back</Button>
              <Typography variant="h5">Images of items in this show</Typography>
              <Gallery images={allShowImages.images} />
              <br />
              <br />
              <Typography variant="h5">Videos of items in this shows</Typography>
              <Videos videos={allShowImages.videos} />
            </div>
          </Drawer>
          <Drawer anchor={"right"} open={binDrawerOpen} onClose={() => setBinDrawerOpen(false)}>
            <div className={drawerStyles.cont}>
              <Button onClick={() => setBinDrawerOpen()}>&lt; Back</Button>
              <Typography variant="h5">
                Bin <Fs1Input onChange={handleUnassignedImages} optimize saveOriginals={saveOriginals} />{" "}
              </Typography>
              <Gallery images={getShowProperty("unassignedImagesBin")} deleteImage={deleteUnassignedImages} />
            </div>
          </Drawer>
        </div>
      </div>

      <Autosave data={show} onSave={updateShow} />
    </div>
  );
};

export default Show;

export const EachItemList = ({ product, i, a, deleteProduct, classes, listItemClasses, deletingProduct, copyingProduct, copyProduct, showMenu = true }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const totalQty = product.variants.reduce((acc, cur) => cur.sizes.reduce((acc, curr) => (curr.quantity || 0) * (curr.size?.length || 0) + acc, 0) + acc, 0);
  // debugger;
  const productImage = (function(){
    
    // (product.images[0] || product.variants[0]?.images?.[0])?process.env.REACT_FS_BASE+(product.images[0] || product.variants[0]?.images?.[0])
    if(product.images[0]) return product.images[0]
    if(product.variants.find(e=>e.images?.length))
    return product.variants.find(e=>e.images.length).images[0]
  return null 

  })()
  return (
  <div className={`product-${product._id}`}>
    <Fragment key={product._id}>
      <ListItem alignItems="flex-start" classes={product._id === deletingProduct ? classes : {}} onClick={() => {
        localStorage.setItem("productId", product._id)
        history.push(`/products/${product._id}`)
      }} style={{ cursor: "pointer" }}>
        <ListItemAvatar>
          <Avatar
            alt=""
            src={productImage?process.env.REACT_APP_FS_BASE+productImage: "https://csfileserver.com/static/607478c193221b0664684c32/7-11-2021/1626021039845__7004__placeholder.jpg" + "/100"}
            style={{ borderRadius: 0 }}
          >
            {" "}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <span style={{ display: "inline-block", width: "50%" }} dangerouslySetInnerHTML={{ __html: product.productId || "" }} />
              <span style={{ display: "inline-block", width: "50%" }}>{totalQty}</span>
            </>
          }
          secondary={<span dangerouslySetInnerHTML={{ __html: product.description || "No Description" }} />}
          classes={listItemClasses}
        />
        {showMenu && (
          <ListItemSecondaryAction classes={product._id === deletingProduct ? classes : {}}>
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  copyProduct(product._id);
                }}
              >
                {" "}
                {product._id === copyingProduct && <CircularProgress size={24} />} Duplicate
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  deleteProduct(product._id);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {i + 1 !== a.length && <Divider variant="inset" component="li" />}
    </Fragment>
  </div>
  );
};
import React, { Fragment, useRef, useEffect, useState } from "react";
import Fs1Input from "../layout/FsInput";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { filterOptions } from "./ProductForm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { sizeDefaults } from "../../utils/defaults";
import { GridList, GridListTile, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import { Autosave } from "react-autosave";
import { Typography } from "@material-ui/core";
import Gallery, { Videos } from "../layout/Gallery";
import { useAlert } from "../../context/alert";
import api from "../../utils/api";

const useCardContentStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 30px 10px 7px",
    "&:last-child": {
      paddingBottom: 10,
    },
  },
}));

const VariantForm = ({ getVariantProperty, change, updateVariant, variant, product }) => {
  const { showAlert } = useAlert();
  const [colorAutoCompleteValue, setColorAutoCompleteValue] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const handleSizesChange = (index, key, value) => {
    const tempSizes = [...getVariantProperty("sizes")];
    const tempSize = { ...tempSizes[index] };
    tempSize[key] = value;
    tempSizes[index] = tempSize;
    change("sizes", tempSizes);
  };

  const colorInputRef = useRef();
  const color = getVariantProperty("color");
  const images = getVariantProperty("images");

  function getAllOtherSizes() {
    return convert2DArrTo1D(getVariantProperty("sizes").map((e) => e.size));
  }

  useEffect(() => {
    api.get("/autocompleteOptions/colors").then((e) => setColors(e.data.map((e) => e.name)));
    api.get("/autocompleteOptions/sizes").then((e) => setSizes(e.data.map((e) => e.name)));
  }, []);

  useEffect(() => {
    if (colorAutoCompleteValue === "Select Color") {
      colorInputRef.current.focus();
      colorInputRef.current.select();
    }
  }, [colorAutoCompleteValue]);

  const addSize = () => {
    const tempSizes = [...getVariantProperty("sizes"), sizeDefaults];
    change("sizes", tempSizes);
  };
  const deleteSize = (index) => {
    const tempSizes = [...getVariantProperty("sizes")];
    tempSizes.splice(index, 1);
    change("sizes", tempSizes);
  };

  const handleNewImage = (e) => {
    const tempImages = [...images, ...e];
    updateVariant({ images: tempImages, _id: variant._id });
    change("images", tempImages);
  };

  const saveOriginals = (arr) => {
    const tempImages = [...getVariantProperty("originalImages"), ...arr];
    updateVariant({ originalImages: tempImages, _id: variant._id });
    change("originalImages", tempImages);
  };

  const onAssignExistingImages = (arr) => {
    handleNewImage(arr);
    const originalImages = arr.map((e) => product.show.unassignedImagesBinOriginals[product.show.unassignedImagesBin.indexOf(e)]);
    saveOriginals(originalImages);
    api.put("/shows/" + product.show._id, {
      unassignedImagesBin: product.show.unassignedImagesBin.filter((e) => !arr.includes(e)),
      unassignedImagesBinOriginals: product.show.unassignedImagesBinOriginals.filter((e) => !originalImages.includes(e)),
    });
  };

  const deleteImage = (i) => {
    const tempImages = [...images];
    tempImages.splice(i, 1);
    updateVariant({ images: tempImages });
    change("images", tempImages);
  };

  const fillValues = (i) => {
    const tempSizes = [...getVariantProperty("sizes")];
    const tempSize = { ...tempSizes[i] };
    Object.entries(tempSize).map(([key, value]) => {
      if (value === "" || value === null || value === undefined) {
        tempSize[key] = tempSizes[i - 1][key] ?? value;
      }
    });
    tempSizes[i] = tempSize;
    change("sizes", tempSizes);
  };
  return (
    <form
      style={{
        minHeight: "88%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Card style={{ marginBottom: 8 }}>
          <CardContent style={{ padding: 9 }}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Autocomplete
                  size="small"
                  filterOptions={filterOptions}
                  options={colors.filter((z) => !product.variants.find((e) => e.color === z))}
                  loading={!colors.length}
                  value={color}
                  onChange={(e, v) => change("color", v || "")}
                  inputValue={colorAutoCompleteValue}
                  onInputChange={(e, v) => setColorAutoCompleteValue(v)}
                  renderInput={(params) => (
                    //the onBlur is so he can add new things besides from the autocomplete suggeswtions. and the condition before calling change in the onBlur, is because
                    <TextField
                      {...params}
                      size="small"
                      onBlur={(e) => {
                        const v = e.target.value;
                        if (!v) return;
                        if (product.variants.find((e) => e.color.trim().toUpperCase() === v.trim().toUpperCase()) && v !== color) {
                          showAlert({ message: "This color already exists for this item" });
                        } else {
                          change("color", v);
                        }
                      }}
                      label="Color"
                      inputRef={colorInputRef}
                      error={
                        colorAutoCompleteValue.trim() === "" ||
                        (product.variants.filter((e) => e.color.trim().toUpperCase() === colorAutoCompleteValue.trim().toUpperCase()).length >= 1 &&
                          color.trim().toUpperCase() !== colorAutoCompleteValue.trim().toUpperCase())
                      }
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Fs1Input onChange={handleNewImage} saveOriginals={saveOriginals} optimize onDoneSelecting={onAssignExistingImages} existingImages={product.show.unassignedImagesBin || []} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {getVariantProperty("sizes").map((size, i, a) => (
          <Size
            {...{
              key: i,
              getAllOtherSizes,
              handleSizesChange,
              fillValues,
              deleteSize,
              size,
              i,
            }}
          />
        ))}
        <Button variant="contained" color="primary" onClick={addSize}>
          + Add Size
        </Button>
        <br />
        <br />
        <br />
        <br />
      </div>
      <div>
        <Typography variant="h5">Images</Typography>
        <Gallery images={images.filter((e) => !isVid(e))} deleteImage={deleteImage} />
      </div>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h5">Videos</Typography>

      <Videos videos={images.filter(isVid)} deleteVideo={deleteImage} />
      <Autosave data={variant} onSave={updateVariant} />
    </form>
  );
};

export default VariantForm;

function Size({ handleSizesChange, fillValues, deleteSize, getAllOtherSizes, size, i }) {
  const cardContentStyles = useCardContentStyles();
  const showDeleteButton = i > 0;
  const showSameButton = i > 0 && (!size.quantity || !size.price);
  const [sizeAutocompleteChange, setSizeAutocompleteChange] = useState("");
  const [sizes, setSizes] = useState([]);
  const { showAlert } = useAlert();

  useEffect(() => {
    api.get("/autocompleteOptions/sizes").then((e) => setSizes(e.data.map((e) => e.name)));
  }, []);

  return (
    <Fragment key={size._id || i}>
      <Card style={{ marginBottom: 8 }}>
        <CardContent classes={cardContentStyles}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                multiple
                filterOptions={filterOptions}
                options={sizes.filter((e) => !getAllOtherSizes().find((b) => b.toLowerCase() === e.toLowerCase()))}
                loading={!sizes.length}
                value={size.size}
                onChange={(e, v) => {
                  handleSizesChange(i, "size", v || "");
                }}
                inputValue={sizeAutocompleteChange}
                onInputChange={(e, v) => {
                  setSizeAutocompleteChange(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={(e) => {
                      const v = e.target.value.trim();
                      if (v) {
                        if (!getAllOtherSizes().find((b) => b.toLowerCase() === v.toLowerCase())) {
                          handleSizesChange(i, "size", [...size.size, v]);
                        } else {
                          e.target.focus();
                          showAlert({ message: "This size already exists for this color" });
                        }
                      }
                    }}
                    onChange={(e) => {
                      let v = e.target.value;
                      if (v.endsWith(" ") && v.trim()) {
                        v = v.trim();
                        if (!getAllOtherSizes().find((b) => b.toLowerCase() === v.toLowerCase())) {
                          handleSizesChange(i, "size", [...size.size, v]);
                        } else {
                          setSizeAutocompleteChange("");
                          showAlert({ message: "This size already exists for this color" });
                        }
                      }
                    }}
                    label="Size"
                    error={!size.size.length}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size="small"
                label="Quantity"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                value={size.quantity}
                onChange={(e) => handleSizesChange(i, "quantity", e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size="small"
                label="Price"
                type="number"
                variant="outlined"
                fullWidth
                value={size.price}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleSizesChange(i, "price", e.target.value)}
              />
            </Grid>
            {showSameButton && (
              <Grid item xs={1}>
                <IconButton onClick={() => fillValues(i)}>
                  <PlayForWorkIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={1}>
              {showDeleteButton && (
                <IconButton onClick={() => deleteSize(i)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}

function convert2DArrTo1D(arrayOfArrays) {
  const newArr = [];
  arrayOfArrays.forEach((arr) => {
    newArr.push(...arr);
  });
  return newArr;
}

export function isVid(src) {
  if (typeof src !== "string" || !src.length) return false;
  const vidTypes = ["mp4"];
  const lastIndOfDot = src.lastIndexOf(".");
  const ext = src.slice(lastIndOfDot + 1, src.length);
  return vidTypes.includes(ext);
}

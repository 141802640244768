import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import { imageStyles } from "../layout/Gallery";

const SelectUnassignedImages = ({ images = [], open, setOpen, onDone }) => {
  const [selectedImages, setSelectedImages] = useState([]);

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{"Click on Images to select"}</DialogTitle>

        <div style={{ padding: 15 }}>
          {images.map((img, i) => {
            const styles = { ...imageStyles, cursor: "pointer", maxWidth: 70 };
            if (selectedImages.includes(img)) {
              styles.border = "4px solid #3f51b5";
            }
            return (
              <img
                key={i}
                src={process.env.REACT_APP_FS_BASE+img + "/100"}
                alt=""
                style={styles}
                onClick={() => {
                  if (selectedImages.includes(img)) {
                    setSelectedImages(selectedImages.filter((e) => e !== img));
                  } else {
                    setSelectedImages([...selectedImages, img]);
                  }
                }}
              />
            );
          })}
        </div>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              onDone(selectedImages);
              setOpen(false);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectUnassignedImages;

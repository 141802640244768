import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useAuthState } from "../../context/auth";

const Login = ({ history }) => {
  const { loginUser, isAuthenticated } = useAuthState();

  const location = useLocation();

  const [invalidCreds, setInvalidCreds] = useState(false);
  const [data, setData] = useState({
    email: "",
    pass: "",
  });

  if (isAuthenticated) {
    return <Redirect to={location?.state?.from || "/shows"} />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    loginUser(data.email.trim(), data.pass.trim()).catch(() => setInvalidCreds(true));
  };

  return (
    <form onSubmit={onSubmit}>
      {invalidCreds && <p style={{ color: "red" }}>Incorrect Login Credentials</p>}
      <br />
      <TextField label="Email" type="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} variant="outlined" fullWidth required /> <br />
      <br />
      <TextField label="Password" type="password" variant="outlined" fullWidth required value={data.pass} onChange={(e) => setData({ ...data, pass: e.target.value })} />
      <br />
      <br />
      <br />
      <br />
      <Button fullWidth variant="contained" color="primary" type="submit">
        Login
      </Button>
    </form>
  );
};

export default Login;

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import api from "../../../utils/api";
import PaginatedTable from "../../utils/PaginatedTable";

export const StoreAutoComplete = () => {
  const [stores, setStores] = useState([]);
  const [originalStores, setOriginalStores] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [action, setAction] = useState("add");
  const [selectedObj, setSelectedObj] = useState(null);
  const [serachQuery, setSerachQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setStores(originalStores.filter((e) => e.name.toLowerCase().includes(serachQuery.toLowerCase())));
  }, [serachQuery]);

  const fetchData = () => {
    api.get("/autocompleteOptions/stores").then((e) => {
      setStores(e.data);
      setOriginalStores(e.data);
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAdd = async () => {
    await api.post("/autocompleteOptions/stores", { name });
    fetchData();
    handleDialogClose();
  };

  const handleUpdate = async () => {
    await api.put(`/autocompleteOptions/stores/${selectedObj._id}`, { name });
    fetchData();
    handleDialogClose();
  };

  const handleUpdateDelete = async (obj, action) => {
    setSelectedObj(obj);
    if (action === "delete") {
      await api.delete(`/autocompleteOptions/stores/${obj._id}`);
      fetchData();
    } else {
      setAction("update");
      setName(obj.name);
      handleDialogOpen();
    }
  };

  return (
    <div>
      {stores ? (
        <>
          <div>
            <TextField
              onChange={(e) => {
                setSerachQuery(e.target.value);
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Search Store"
              type="text"
              fullWidth
              variant="standard"
              value={serachQuery}
              style={{ marginBottom: "2rem" }}
            />
            <PaginatedTable
              columns={[
                { id: "name", label: "Name", minWidth: 220 },
                { id: "action", label: "Action", align: "center", minWidth: 10 },
              ]}
              rows={stores.map((store) => ({
                _id: store._id,
                name: store.name,
              }))}
              handleUpdateDelete={handleUpdateDelete}
            />
          </div>
        </>
      ) : (
        "No Data Found"
      )}

      <div style={{ marginTop: "1rem", textAlign: "center" }}>
        <Button
          variant="contained"
          store="primary"
          onClick={() => {
            setName("");
            setAction("add");
            handleDialogOpen();
          }}
        >
          + New Store
        </Button>
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{action === "add" ? "New Store" : "Update Store"}</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="name"
            label="Store Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {action === "add" ? <Button onClick={handleAdd}>Add</Button> : <Button onClick={handleUpdate}>Update</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};

import "date-fns";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filterOptions } from "./ProductForm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment } from "@material-ui/core";
import api from "../../utils/api";


const Show = ({ getShowProperty, change, expanded }) => {
  const nameInputRef = useRef();
  const storeRef = useRef();
  const showName = getShowProperty("name");
  const storeName = getShowProperty("store");

  const [stores, setStores] = useState([])
  const [vendors, setVendors] = useState([])
  
 useEffect(() => {
    api.get("/autocompleteOptions/stores").then((e) => setStores(e.data.map((e) => e.name)));
    api.get("/autocompleteOptions/vendors").then((e) => setVendors(e.data.map((e) => e.name)));
  }, []);


  useEffect(() => {
    setTimeout(() => {
      if (storeName === "Enter Store") {
        storeRef.current.focus();
        storeRef.current.select();
      } else if (showName === "New Show Name") {
        setTimeout(() => {
          nameInputRef.current.focus();
          nameInputRef.current.select();
        });
      }
    });
  }, [showName, storeName, expanded]);

 

  return (
    <form style={{ width: "100%" }}>
      <Autocomplete
        size="small"
        filterOptions={filterOptions}
        options={stores}
        loading={!stores.length}
        value={storeName}
        onChange={(e, v) => change("store", v || "")}
        renderInput={(params) => (
          <TextField {...params} inputRef={storeRef} onBlur={(e) => change("store", e.target.value)} label="Store" error={storeName.trim() === ""} variant="outlined" size="small" />
        )}
      />
      <br />
      <Autocomplete
        size="small"
        filterOptions={filterOptions}
        options={vendors}
        loading={!vendors.length}
        value={showName}
        onChange={(e, v) => change("name", v || "")}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Name"
            inputRef={nameInputRef}
            error={showName.trim() === ""}
            label="Name"
            variant="outlined"
            onBlur={(e) => change("name", e.target.value)}
            size="small"
          />
        )}
      />
      <br />
      <br />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="none"
          size="small"
          fullWidth
          id="date-picker-dialog"
          label="Date"
          onChange={(newDate) => change("date", newDate)}
          value={getShowProperty("date")}
          format="MM/dd/yyyy"
          inputVariant="outlined"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
      <br />
      <br />
      <TextField label="Location" fullWidth value={getShowProperty("location")} onChange={(e) => change("location", e.target.value)} variant="outlined" placeholder="Location" size="small" />
      <br />
      <br />
      <TextField
        label="Season"
        fullWidth
        value={getShowProperty("defaultProductSeason")}
        onChange={(e) => change("defaultProductSeason", e.target.value)}
        variant="outlined"
        placeholder="Season"
        size="small"
      />
      <br />
      <br />
      <TextField label="PO" fullWidth value={getShowProperty("PO")} onChange={(e) => change("PO", e.target.value)} variant="outlined" placeholder="PO" size="small" />
      <br />
      <br />
      <TextField
        label="PO Start Ship Date"
        fullWidth
        value={getShowProperty("POStartShipDate")}
        onChange={(e) => change("POStartShipDate", e.target.value)}
        variant="outlined"
        placeholder="POStartShipDate"
        size="small"
      />
      <br />
      <br />
      <TextField
        label="PO Cancel Date"
        fullWidth
        value={getShowProperty("POCancelDate")}
        onChange={(e) => change("POCancelDate", e.target.value)}
        variant="outlined"
        placeholder="POCancelDate"
        size="small"
      />
      <br />
      <br />
      <TextField
        label="Markup"
        fullWidth
        value={getShowProperty("markup")}
        onChange={(e) => change("markup", e.target.value)}
        variant="outlined"
        placeholder="markup"
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <br />
      <br />
      <TextField label="Notes" fullWidth multiline rows="6" onChange={(e) => change("notes", e.target.value)} value={getShowProperty("notes")} variant="outlined" placeholder="Notes" size="small" />
    </form>
  );
};

export default Show;

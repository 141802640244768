import { AccordionSummary, Accordion, Typography, Button, AccordionDetails, CircularProgress, Divider } from "@material-ui/core";
import React from "react";
import { isVid } from "../forms/VariantForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import Gallery from "../layout/Gallery";
import { Videos } from "../layout/Gallery";
import api from "../../utils/api";
import { useState } from "react";
import { useEffect } from "react";
import { SearchAndFilter } from "./Shows";
import { Fragment } from "react";

export default function AllImages() {
  const [allShowsImages, setAllShowsImages] = useState({ originalRes: null, matchedSearch: null });
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  async function getAllImages(params, limit = 10, skip = 0) {
    return api
      .get(`/shows/`, {
        params: {
          ...params,
          limit: limit + 1,
          skip,
          select: "unassignedImagesBin name date",
          populate: {
            path: "products",
            select: "images variants",
            populate: {
              path: "variants",
              select: "images",
            },
          },
        },
      })
      .then(({ data }) => {
        let dataSet = data;
        if (data?.length === limit + 1) {
          setHasMore(true);
          dataSet = data.slice(0, -1);
        } else {
          setHasMore(false);
        }
        setSkip(skip);
        setLimit(limit);
        const allImagesPerShow = dataSet.map((show) => {
          const imagesArr = [];
          const vidsArr = [];
          for (let j = 0; j < show.unassignedImagesBin.length; j++) {
            if (isVid(show.unassignedImagesBin[j])) {
              vidsArr.push(show.unassignedImagesBin[j]);
            } else {
              imagesArr.push(show.unassignedImagesBin[j]);
            }
          }
          show.products.forEach((prod) => {
            for (let j = 0; j < prod.images.length; j++) {
              if (isVid(prod.images[j])) {
                vidsArr.push(prod.images[j]);
              } else {
                imagesArr.push(prod.images[j]);
              }
            }
            prod.variants.forEach((variant) => {
              for (let j = 0; j < variant.images.length; j++) {
                if (isVid(variant.images[j])) {
                  vidsArr.push(variant.images[j]);
                } else {
                  imagesArr.push(variant.images[j]);
                }
              }
            });
          });
          return {
            _id: show._id,
            name: show.name,
            date: show.date,
            images: imagesArr,
            videos: vidsArr,
          };
        });
        setAllShowsImages((prevState) => {
          let prevOriginalRes = prevState?.originalRes ?? [];
          let prevMatchedSearch = prevState?.matchedSearch ?? [];
          if (skip === 0) {
            prevOriginalRes = [];
            prevMatchedSearch = [];
          }
          return { originalRes: prevOriginalRes.concat(allImagesPerShow), matchedSearch: prevMatchedSearch.concat(allImagesPerShow) };
        });
      });
  }

  useEffect(getAllImages, []);

  if (!allShowsImages.matchedSearch) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <SearchAndFilter
        searchProps={{ keysToSearchIn: ["name"], listOfObjects: allShowsImages.originalRes, onListFiltered: (newList) => setAllShowsImages((e) => ({ ...e, matchedSearch: newList })) }}
        filterProps={{ onChangeParams: getAllImages }}
      />
      <br />
      {allShowsImages.matchedSearch
        .filter((e) => e.videos.length || e.images.length)
        .map((e, i) => (
          <Fragment key={e._id}>
            <Typography variant="h6">
              <span dangerouslySetInnerHTML={{ __html: e.name }}></span>
              <Link to={`/shows/${e._id}`} style={{ verticalAlign: "sub", color: "#757575" }} onClick={(e) => e.stopPropagation()}>
                <LaunchIcon />
              </Link>
              <span style={{ fontSize: 14, float: "right", fontWeight: 400 }}> {e.date ? new Date(e.date).toLocaleDateString() : ""}</span>
            </Typography>
            <Divider />
            <br />
            <div>
              <Gallery images={e.images} />
              <br />
              <br />
              <Videos videos={e.videos} />
            </div>
          </Fragment>
        ))}
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ width: "200px" }}
          variant="contained"
          color="primary"
          disabled={!hasMore}
          onClick={() => {
            getAllImages({}, limit, skip + limit);
          }}
        >
          Load More
        </Button>
      </div>
    </div>
  );
}

export const sizeDefaults = {
  size: [],
  quantity: "",
  price: "",
};
export const colorDefaults = {
  color: "",
  images: [],
  originalImages: [],
  sizes: [sizeDefaults],
};
export const productDefaults = {
  brand: "",
  group: "",
  productId: "",
  images: [""],
  originalImages: [],
  notes: "",
  season: "",
  scale: "",
  description: "",
};
export const showDefaults = {
  store: "",
  unassignedImagesBin: [],
  unassignedImagesBinOriginals: [],
  data: new Date(),
  location: "",
  archived: false,
  completed: false,
  notes: "",
  name: "",
  products: [],
  POCancelDate: "",
  POStartShipDate: "",
  PO: "",
  defaultProductSeason: "",
};

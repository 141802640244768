import React, { useState, createContext, useContext, useEffect } from "react";
import { withRouter, matchPath } from "react-router";
import api from "../utils/api";

const ShowContext = createContext();

export const useShow = () => useContext(ShowContext);

const ShowState = (props) => {
  const [show, setShow] = useState();
  const [matchingItems, setMatchingItems] = useState(null);
  const match = matchPath(props.location.pathname, {
    path: "/shows/:show_id",
    exact: true,
    strict: false,
  });

  const getShow = (params) =>
    //get first variant image in case there is no product image to show on thumbnail
    api
      .get(`/shows/${match.params.show_id}?`, {
        params: {
          ...params,
          populate: {
            path: "products",
            populate: {
              path: "variants",
            },
          },
        },
      })
      .then((res) => {
        setMatchingItems(res.data.products);
        setShow(res.data);
      });
  useEffect(() => {
    if (match?.params?.show_id) {
      setShow(null);
      setMatchingItems(null);
      getShow();
    } else {
      setShow(null);
      setMatchingItems(null);
    }
  }, [props.location.pathname]);
  return (
    <ShowContext.Provider
      value={{
        show,
        matchingItems,
        setMatchingItems,
        getShow,
        setShow,
      }}
    >
      {props.children}
    </ShowContext.Provider>
  );
};

export default withRouter(ShowState);

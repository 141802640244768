import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import api from "../../../utils/api";
import PaginatedTable from "../../utils/PaginatedTable";
import { Autocomplete } from "@material-ui/lab";

export const SubSubGroupAutoComplete = () => {
  const [subSubGroups, setSubSubGroups] = useState([]);
  const [originalSubSubGroups, setOriginalSubSubGroups] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [lilyGroups, setLilyGroups] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [lilyGroupsValue, setLilyGroupsValue] = useState([]);
  const [aka, setAka] = useState("");
  const [action, setAction] = useState("add");
  const [selectedObj, setSelectedObj] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
    api.get("/autocompleteOptions/stores").then((e) => {
      setStoresData(e.data.map((e) => e.name));
    });
    api.get("/autocompleteOptions/lilyGroups").then((e) => {
      setLilyGroups(e.data);
    });
  }, []);

  useEffect(() => {
    setSubSubGroups(originalSubSubGroups.filter((e) => e.name.toLowerCase().includes(searchQuery.toLowerCase())));
  }, [searchQuery]);

  const fetchData = () => {
    api.get("/autocompleteOptions/subSubGroups").then((e) => {
      setSubSubGroups(e.data);
      setOriginalSubSubGroups(e.data);
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAdd = async () => {
    await api.post("/autocompleteOptions/subSubGroups", { name, store, aka, lilyGroups: lilyGroupsValue });
    fetchData();
    handleDialogClose();
  };

  const handleUpdate = async () => {
    await api.put(`/autocompleteOptions/subSubGroups/${selectedObj._id}`, { name, store, aka, lilyGroups: lilyGroupsValue });
    fetchData();
    handleDialogClose();
  };

  const handleUpdateDelete = async (obj, action) => {
    setSelectedObj(obj);
    if (action === "delete") {
      await api.delete(`/autocompleteOptions/subSubGroups/${obj._id}`);
      fetchData();
    } else {
      setAction("update");
      setName(obj.name);
      setStore(obj.store);
      setAka(obj.aka);
      setLilyGroupsValue(obj.lilyGroups);
      handleDialogOpen();
    }
  };

  return (
    <div>
      {subSubGroups ? (
        <>
          <div>
            <TextField
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Search Sub Sub Group"
              type="text"
              fullWidth
              variant="standard"
              value={searchQuery}
              style={{ marginBottom: "2rem" }}
            />
            <PaginatedTable
              columns={[
                { id: "name", label: "Name", minWidth: 100 },
                { id: "lilyGroupName", label: "Lily Group", minWidth: 150 },
                { id: "store", label: "Store Name", minWidth: 150 },
                { id: "aka", label: "aka", minWidth: 100 },
                { id: "action", label: "Action", align: "center", minWidth: 10 },
              ]}
              rows={subSubGroups.map((subSubGroup) => ({
                _id: subSubGroup._id,
                name: subSubGroup.name,
                store: subSubGroup.store,
                aka: subSubGroup.aka,
                lilyGroupName: subSubGroup?.lilyGroups?.map((lilyGroup) => lilyGroup?.name).join(", ") || "------",
                lilyGroups: subSubGroup?.lilyGroups || [],
              }))}
              handleUpdateDelete={handleUpdateDelete}
            />
          </div>
        </>
      ) : (
        "No Data Found"
      )}

      <div style={{ marginTop: "1rem", textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setName("");
            setStore("");
            setAka("");
            setLilyGroupsValue([]);
            setAction("add");
            handleDialogOpen();
          }}
        >
          + New Sub Sub Group
        </Button>
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{action === "add" ? "New Sub Sub Group" : "Update Sub Sub Group"}</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="name"
            label="Sub Sub Group Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
          />
          <Autocomplete
            size="small"
            options={lilyGroups || []}
            getOptionLabel={(option) => option?.name}
            loading={!lilyGroups?.length || true}
            value={lilyGroupsValue}
            multiple
            getOptionSelected={(option, value) => option?.id === value?.id}
            onChange={(e, v) => {
              setLilyGroupsValue(v);
            }}
            renderInput={(params) => <TextField {...params} label="Associated Lily Group" />}
          />
          <Autocomplete
            size="small"
            options={storesData || []}
            loading={!storesData.length}
            value={store}
            onChange={(e, v) => {
              setStore(v);
            }}
            renderInput={(params) => <TextField {...params} label="Sub Sub Group Store Name" />}
          />
          <TextField
            onChange={(e) => {
              setAka(e.target.value);
            }}
            margin="dense"
            id="name"
            label="Sub Sub Group aka"
            type="text"
            fullWidth
            variant="standard"
            value={aka}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {action === "add" ? <Button onClick={handleAdd}>Add</Button> : <Button onClick={handleUpdate}>Update</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};

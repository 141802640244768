import * as React from "react";

import { Button, IconButton, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";

import { Edit, Delete } from "@material-ui/icons";
import { useState } from "react";

export default function PaginatedTable({ columns, rows, handleUpdateDelete }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDeletePopoverClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };

  const handleDeletePopoverClose = () => {
    setAnchorEl(null);
  };

  const deletePopoverOpen = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        {rows.length !== 0 ? (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "action" ? (
                            <>
                              <IconButton onClick={() => handleUpdateDelete(row, "update")} aria-label="edit" color="primary">
                                <Edit />
                              </IconButton>
                              <IconButton
                                aria-describedby="delete-popover"
                                onClick={(e) => {
                                  setSelectedRow(row);
                                  handleDeletePopoverClick(e);
                                }}
                                aria-label="delete"
                                color="secondary"
                              >
                                <Delete />
                              </IconButton>
                            </>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <div style={{ textAlign: "center", margin: "2rem" }}>No Data Found</div>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Popover
        id="delete-popover"
        open={deletePopoverOpen}
        anchorEl={anchorEl}
        onClose={handleDeletePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "1rem" }}>Are you sure you want to delete this record?</div>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              handleUpdateDelete(selectedRow, "delete");
              handleDeletePopoverClose();
            }}
            color="secondary"
            variant="contained"
            style={{ margin: "1rem" }}
          >
            Yes
          </Button>
          <Button onClick={handleDeletePopoverClose} color="primary" variant="contained" style={{ margin: "1rem" }}>
            No
          </Button>
        </div>
      </Popover>
    </Paper>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VariantForm from "../forms/VariantForm";
import { colorDefaults } from "../../utils/defaults";
import { Avatar, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Drawer, TableCell,CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import Currency from '../utils/Currency';
import api from "../../utils/api";
import { useSavingState } from "../../context/saving";
import { cleanObjFromMongoData } from "./Show";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useDrawerStyles = makeStyles((t) => {
  const offset = t.mixins.toolbar.minHeight;
  return {
    root: {
      backgroundColor: "#e0e0e0e6",
      padding: 10,
    },
    backdropRoot: {
      top: offset,
    },
    cont: {
      width: "calc(50vw + 250px)",
      maxWidth: "95vw",
      height: "100%",
    },
  };
});

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  root: {
    borderBottom:'none',
    padding:0
  },
}));

const Variants = ({ variant, setVariant, getProduct, product, createVariant }) => {
  const drawerStyles = useDrawerStyles();
  const classes = useStyles();
  const cellStyles = useTableCellStyles();
  const [deletingVariant, setDeletingVariant] = useState("");
  const [copyingVariant, setCopyingVariant] = useState("");
  const { saving, saved, error, savingState } = useSavingState();

  const getVariantProperty = (name) => {
    return variant?.[name] ?? colorDefaults[name];
  };

  const change = (name, value) => {
    saving();
    setVariant((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const updateVariant = (data) => {
    if (data) {
      const body = {
        product: data.product,
        images: data.images,
        originalImages: data.originalImages,
        sizes: data.sizes,
      };
      if (data.color) {
        body.color = data.color;
      }
      return api.put(`/variants/${data._id}`, body).then(getProduct).then(saved).catch(error);
    }
  };

  const copyVariant = async (variant) => {
    setCopyingVariant(variant._id);
    variant = cleanObjFromMongoData(variant);
    variant.sizes = variant.sizes.map(cleanObjFromMongoData);
    await createVariant(variant);
    setCopyingVariant("");
  };

  const deleteVariant = (id) => {
    if (window.confirm("Are you sure you want to delete this color?")) {
      setDeletingVariant(id);
      api
        .delete(`/variants/${id}`)
        .then(getProduct)
        .finally(() => setDeletingVariant(""));
    }
  };

  const onCloseDrawer = () => {
    // if(savingState !=='Saved'){
    //  if(window.confirm('Are you sure you want to leave without saving changes?')){
    //    setVariant(null)
    //  }else{
    //    return
    //  }
    // }
    setVariant(null);
  };

  return (
    <div>
      <List style={{ width: "100%" }}>
        {product.variants.map((variant, i, a) => (
          <EachList {...{ variant, i, a, deletingVariant, classes, setVariant, cellStyles, deletingVariant, copyingVariant, copyVariant, deleteVariant, key: variant._id }} />
        ))}
      </List>
      <Drawer
        hysteresis={1}
        PaperProps={{ classes: { root: drawerStyles.root } }}
        BackdropProps={{ classes: { root: drawerStyles.backdropRoot } }}
        anchor={"right"}
        open={Boolean(variant)}
        onClose={onCloseDrawer}
      >
        <div className={drawerStyles.cont}>
          <Button onClick={onCloseDrawer}>&lt; Back</Button>
          <VariantForm {...{ getVariantProperty, product, change, variant, updateVariant, variant }} />
        </div>
      </Drawer>
    </div>
  );
};

export default Variants;

const EachList = ({variant, i, a,classes,setVariant,cellStyles,deletingVariant,copyingVariant,copyVariant,deleteVariant})=>{
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment key={variant._id}>
      <ListItem alignItems="flex-start" classes={variant._id === deletingVariant ? classes : {}} onClick={() => setVariant(variant)} style={{ cursor: "pointer" }}>
        <ListItemAvatar>
          <Avatar alt="" src={variant.images[0] ? process.env.REACT_APP_FS_BASE+variant.images[0] + "/100" : "/img/placeholder.jpg"} style={{ borderRadius: 0 }}>
            {" "}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={variant.color}
          secondary={
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 75px)",
                paddingTop: 14,
              }}
            >
              {objToArr(variant.sizes)
                .sort((a, b) => (parseInt(a.size) == parseInt(b.size) ? (b.size.includes("X") ? -1 : 1) : parseInt(a.size) > parseInt(b.size) ? 1 : -1))
                .map((size) => (
                  <Fragment key={size.size}>
                    <TableCell classes={cellStyles}>{size.size}</TableCell>
                    <TableCell classes={cellStyles}>{size.quantity || 0}pcs</TableCell>
                    <TableCell classes={cellStyles}>
                      <Currency amount={size.price || 0} />
                    </TableCell>
                  </Fragment>
                ))}
            </div>
          }
        />
        <ListItemSecondaryAction classes={variant._id === deletingVariant ? classes : {}}>
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
              onClick={(e) => {
                copyVariant(variant).then(handleClose);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {" "}
              {variant._id === copyingVariant && <CircularProgress size={24} />} Duplicate
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                deleteVariant(variant._id);
                e.stopPropagation();
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      {i + 1 !== a.length && <Divider variant="inset" component="li" />}
    </Fragment>
  );
}




/*
initial sizes layout:

  [
    {
      size:[1,2],                  <-------------------------------
      qty:1,
      price:1
    }
  ]

we want to make a size objet for each of the 'size' array so it should look like this
  [
    {
      size:1,                       <-----------------------------------
      qty:1,
      price:1
    },
     {
      size:2,                       <----------------------------------
      qty:1,
      price:1
    }
  ]
*/
function objToArr(a){
  const newL=[]
  for(let i=0;i<a.length;i++){
      for(let j=0;j<a[i].size.length;j++){
          newL.push({...JSON.parse(JSON.stringify(a[i])),size:a[i].size[j]})
      }
  }
  return newL
}
import React, { useState, createContext, useContext } from "react";
import api from "../utils/api";
import { useEffect } from "react";
import { useMemo } from "react";

export const AuthContext = createContext();

export const useAuthState = () => useContext(AuthContext);

const AuthState = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const getUserProfile = async () => {
    const profile = await api.get("/auth/me");
    if (profile.status === 200) {
      setUserProfile(profile.data);
      setIsAuthenticated(true);
    }
  };

  const loginUser = async (email, password) => {
    //const res = await api.post("/auth/login", { email, password }, {headers: {credentials: 'include'}});
    const res = await api.post("/auth/login", { email, password }, { credentials: 'include' });
    if (res.status === 200) { 
      setIsAuthenticated(true);
    };
  };


  const logout = async () => {
    const res = await api.post("/auth/logout");
    if (res.status === 200) {
      setIsAuthenticated(false);
      setUserProfile(null);
      window.location.reload();
    }
  };

  useMemo(() => {
    api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          setIsAuthenticated(false);
          setUserProfile(null);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userProfile,
        getUserProfile,
        loginUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;

import { List, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useShow } from "../../context/show";
import api from "../../utils/api";
import { filterOptions } from "../forms/ProductForm";
import { useQuery } from "../layout/Filters";
import { EachItemList } from "../pages/Show";
import Search from "../layout/Search";
import { stores } from "../forms/ShowForm";

const SeasonReport = () => {
  let firstRender = useRef(true);
  let firstRender2 = useRef(true);
  const { matchingItems, setMatchingItems } = useShow();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery(location.search);
  const [items, setItems] = useState(null);
  const [stores, setStores] = useState([]);

  const [autoCompleteValues, setAutoCompleteValues] = useState({
    season: "",
    group: "",
    ssg: "",
    store: "All",
  });
  const onAutoCompleteValuesChange = (k, v) => {
    setAutoCompleteValues((autoCompleteValues) => ({
      ...autoCompleteValues,
      [k]: v,
    }));
  };

  const [seasons, setSeasons] = useState([]);
  const selectedSeason = query.get("season");
  const setSelectedSeason = (v) => {
    query.set("season", v);
    history.push(`${location.pathname}?${query.toString()}`);
  };
  useEffect(() => {
    api.get("season-report-builder/seasons").then((e) => {
      setSeasons(e.data);
    });
    if (selectedSeason) {
      onAutoCompleteValuesChange("season", selectedSeason);
    }
    api.get("/autocompleteOptions/stores").then((e) => setStores(e.data));

  }, []);


  const [groups, setGroups] = useState([]);
  const selectedGroup = query.get("group");
  const setSelectedGroup = (v) => {
    if (v) {
      query.set("group", v);
    } else {
      query.delete("group");
    }
    history.push(`${location.pathname}?${query.toString()}`);
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (selectedGroup) {
        onAutoCompleteValuesChange("group", selectedGroup);
      }
    } else if (selectedSeason) {
      setSelectedGroup(null);
      setGroups([]);
      setItems(null);
      setMatchingItems(null);
    }
    if (selectedSeason) {
      api.get("season-report-builder/groups/" + selectedSeason).then((e) => {
        setGroups(e.data);
      });
    }
  }, [selectedSeason]);

  const [ssgs, setSsgs] = useState([]);
  const selectedSSG = query.get("ssg");
  const setSelectedSSG = (v) => {
    if (v) {
      query.set("ssg", v);
    } else {
      query.delete("ssg");
    }
    history.push(`${location.pathname}?${query.toString()}`);
  };
  useEffect(() => {
    if (firstRender2.current) {
      firstRender2.current = false;
      if (selectedSSG) {
        onAutoCompleteValuesChange("ssg", selectedSSG);
      }
    } else if (selectedGroup) {
      setSelectedSSG(null);
      setSsgs([]);
      setItems(null);
      setMatchingItems(null);
    }
    if (selectedSeason && selectedGroup) {
      api.get("season-report-builder/ssgs/" + selectedSeason + "/" + selectedGroup).then((e) => {
        setSsgs(e.data);
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedSSG) {
      setItems(null);
      setMatchingItems(null);
      api.get("season-report/" + selectedSeason + "/" + selectedGroup + "/" + selectedSSG).then((e) => {
        setItems(e.data);
        setMatchingItems(e.data);
      });
    }
    return () => setMatchingItems(null);
  }, [selectedSSG]);

  const selectedStore = query.get("store") || "All";
  const setSelectedStore = (v) => {
    if (v) {
      query.set("store", v);
    } else {
      query.delete("store");
    }
    history.push(`${location.pathname}?${query.toString()}`);
  };
  useEffect(() => {
    if (selectedSeason && selectedGroup && selectedSSG) {
      setItems(null);
      setMatchingItems(null);
      api.get("season-report/" + selectedSeason + "/" + selectedGroup + "/" + selectedSSG + "?store=" + (selectedStore == "All" ? "all" : selectedStore)).then((e) => {
        setItems(e.data);
        setMatchingItems(e.data);
      });
    }
  }, [selectedStore, selectedSeason, selectedGroup, selectedSSG]);

  // console.log(selectedSeason);
  return (
    <div>
      <br />
      <Typography variant="h5">Season Report</Typography>
      <br />
      <Autocomplete
        size="small"
        loading={!seasons.length}
        filterOptions={filterOptions}
        options={seasons}
        value={selectedSeason}
        onChange={(e, v) => {
          setSelectedSeason(v);
        }}
        inputValue={autoCompleteValues.season}
        onInputChange={(e, newVal) => onAutoCompleteValuesChange("season", newVal)}
        renderInput={(params) => <TextField {...params} label="Season" variant="outlined" size="small" />}
      />
      <br />
      <br />
      {selectedSeason && (
        <Autocomplete
          size="small"
          loading={!groups.length}
          filterOptions={filterOptions}
          options={groups}
          value={selectedGroup}
          onChange={(e, v) => {
            setSelectedGroup(v);
          }}
          inputValue={autoCompleteValues.group}
          onInputChange={(e, newVal) => onAutoCompleteValuesChange("group", newVal)}
          renderInput={(params) => <TextField {...params} label="Group" variant="outlined" size="small" />}
        />
      )}
      <br />
      <br />
      {selectedGroup && (
        <Autocomplete
          size="small"
          loading={!ssgs.length}
          filterOptions={filterOptions}
          options={ssgs}
          value={selectedSSG}
          onChange={(e, v) => {
            setSelectedSSG(v);
          }}
          inputValue={autoCompleteValues.ssg}
          onInputChange={(e, newVal) => onAutoCompleteValuesChange("ssg", newVal)}
          renderInput={(params) => <TextField {...params} label="SSG" variant="outlined" size="small" />}
        />
      )}
      <br />
      <br />
      <Autocomplete
        size="small"
        filterOptions={filterOptions}
        options={["All", ...stores]}
        loading={!stores.length}
        value={selectedStore}
        onChange={(e, v) => {
          setSelectedStore(v);
        }}
        inputValue={autoCompleteValues.store}
        onInputChange={(e, newVal) => onAutoCompleteValuesChange("store", newVal)}
        renderInput={(params) => <TextField {...params} label="Store" variant="outlined" size="small" />}
      />
      <br></br>
      <br></br>
      {items?.length && matchingItems?.length ? (
        <div style={{ position: "relative" }}>
          <Typography variant="h6">Items</Typography>
          <div>
            <Search
              listOfObjects={items}
              keysToSearchIn={["description", "productId"]}
              onListFiltered={(newList) => {
                setMatchingItems(newList);
              }}
            />
          </div>
          <List style={{ width: "100%" }}>
            {matchingItems.map((product, i, a) => (
              <EachItemList {...{ product, i, a, key: product._id, showMenu: false }} />
            ))}
          </List>
        </div>
      ) : null}
    </div>
  );
};

export default SeasonReport;

import React, { useState, createContext, useContext, useEffect } from "react";
import api from "../utils/api";
import { useAuthState } from "./auth";

const ShowsContext = createContext();

export const useShows = () => useContext(ShowsContext);

const ShowsState = (props) => {
  const [shows, setShows] = useState({ originalRes: null, matchedSearch: null });
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuthState();

  const getShows = (params) => {
    setLoading(true);
    return api
      .get(`/shows`, {
        params: {
          sort: "-createdAt",
          limit: 10000,
          archived: false,
          completed: false,
          ...params,
        },
      })
      .then((res) => {
        setShows({
          originalRes: res.data,
          matchedSearch: res.data,
        });
        setLoading(false);
      });
  };
  useEffect(getShows, [isAuthenticated]);
  return (
    <ShowsContext.Provider
      value={{
        shows,
        loading,
        setLoading,
        getShows,
        setShows,
      }}
    >
      {props.children}
    </ShowsContext.Provider>
  );
};

export default ShowsState;

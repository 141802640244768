import axios from "axios";
import Qs from "qs";

const api = axios.create({
  baseURL: process?.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer: function (params) {
    console.log(Qs.stringify(params));
    return Qs.stringify(params);
  },
});

export default api;

import React, { useEffect, useState } from "react";
import { matchSorter } from "match-sorter";
import { useLocation, useHistory } from "react-router";
import { fade, InputBase, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export default function Search({ keysToSearchIn, listOfObjects, onListFiltered }) {
  const location = useLocation();
  const history = useHistory();
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState(query.get("search") || "");

  useEffect(() => {
    if (searchTerm) {
      onListFiltered(
        matchSorter(listOfObjects, searchTerm, {
          keys: keysToSearchIn,
        }).map((e) => ({
          ...e,
          ...Object.fromEntries(keysToSearchIn.map((key) => [key, e[key]?.replace(new RegExp(`(${searchTerm})`, "gi"), '<span style="font-weight:900;">$1</span>')])),
        }))
      );
      if (searchTerm !== query.get("search")) {
        query.set("search", searchTerm);
        history.push(`${location.pathname}?${query.toString()}`);
      }
    } else {
      onListFiltered(listOfObjects);
      // if (query.get("search") === "") {
      query.delete("search");
      history.push(`${location.pathname}?${query.toString()}`);
      // }
    }
  }, [searchTerm, listOfObjects]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.light, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.light, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    display: "flex",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
